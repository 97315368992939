import React from "react";
import "./index.scss";

import rainIco from "./rain.svg";
import sunIco from "./sun.svg";

function Status(props) {
  return (
    <div className="Status">
      <span className="isRaining">
        <img
          src={props.isRaining ? rainIco : sunIco}
          alt={props.isRaining ? "Raining" : "Not raining"}
        />
      </span>
      <span> </span>
      <span className="temperature">
        {props.temperature} <span className="light">°C</span>
      </span>
    </div>
  );
}

export default Status;
