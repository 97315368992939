import React from "react";
import "./index.scss";

function Toggle(props) {
  return (
    <div
      className="Toggle"
      data-disabled={props.disabled}
      onClick={() => {
        props.setValue(!props.value);
      }}
    >
      <label className="toggleLabel">{props.children}</label>
      <div className="toggleGroup">
        <label className="falseValue value">
          <span>{props.valueForFalse}</span>
        </label>
        <span className="toggleControl" data-value={props.value}></span>
        <label className="trueValue value">
          <span>{props.valueForTrue}</span>
        </label>
      </div>
    </div>
  );
}

export default Toggle;
