const DOMAIN = "serra01.cologno01.ccsys.it";
const APIURL = `https://${DOMAIN}`;

export default {
  temperature: async () => {
    const response = await fetch(`${APIURL}/temperature`);
    const data = await response.text();
    return Math.round(data);
  },
  isRaining: async () => {
    const response = await fetch(`${APIURL}/isRaining`);
    const data = await response.text();
    return data === "true";
  },
  isHeating: async () => {
    const response = await fetch(`${APIURL}/heating`);
    const data = await response.text();
    return data === "true";
  },
  autoMode: async () => {
    const response = await fetch(`${APIURL}/manualMode`);
    const data = await response.text();
    return data !== "true";
  },
  lidOpen: async () => {
    const response = await fetch(`${APIURL}/lidOpen`);
    const data = await response.text();
    return data === "true";
  },
  writeAutoMode: async (value) => {
    const response = await fetch(`${APIURL}/manualMode`, {
      method: "post",
      body: JSON.stringify(!value),
    });
    const data = await response.text();
    return data !== "true";
  },
  writeLidOpen: async (value) => {
    const response = await fetch(`${APIURL}/lidOpen`, {
      method: "post",
      body: JSON.stringify(value),
    });
    const data = await response.text();
    return data === "true";
  },
};
