import React, { useState, useEffect } from "react";
import "./index.scss";

import API from "../API";
import Status from "../Status";
import Toggle from "../Toggle";

function App() {
  const [temperature, setTemperature] = useState(20);
  const [isRaining, setIsRaining] = useState(false);
  const [autoMode, setAutoMode] = useState(false);
  const [lidOpen, setLidOpen] = useState(false);
  const [isHeating, setIsHeating] = useState(false);

  const fetchData = () => {
    Promise.all([
      API.temperature(),
      API.isRaining(),
      API.autoMode(),
      API.lidOpen(),
      API.isHeating(),
    ])
      .then(([temperature, isRaining, autoMode, lidOpen, isHeating]) => {
        setTemperature(temperature);
        setIsRaining(isRaining);
        setAutoMode(autoMode);
        setLidOpen(lidOpen);
        setIsHeating(isHeating);
        document.body.setAttribute("data-loaded", "");
      })
      .catch((err) => console.log(err));
  };

  const writeAutoMode = (value) => {
    document.body.setAttribute("data-thinking-autoMode", "");
    API.writeAutoMode(value).then((value) => {
      setAutoMode(value);
      document.body.removeAttribute("data-thinking-autoMode");
    });
  };

  const writeLidOpen = (value) => {
    document.body.setAttribute("data-thinking-lidOpen", "");
    API.writeLidOpen(value).then((value) => {
      setLidOpen(value);
      document.body.removeAttribute("data-thinking-lidOpen");
    });
  };

  useEffect(() => {
    fetchData();
    setInterval(() => {
      fetchData();
    }, 1000);
  }, []);

  return (
    <div className={`App ${isHeating ? "heating" : ""}`}>
      <header className="App-header">
        <h1>
          <span className="light">My</span> Greenhouse
        </h1>
      </header>
      <main>
        <Status temperature={temperature} isRaining={isRaining} />
        <Toggle
          id="autoMode"
          value={autoMode}
          setValue={writeAutoMode}
          valueForFalse="Off"
          valueForTrue="On"
        >
          Auto Mode
        </Toggle>
        <Toggle
          id="lidOpen"
          value={lidOpen}
          setValue={writeLidOpen}
          valueForFalse="Closed"
          valueForTrue="Open"
        >
          Lid
        </Toggle>
      </main>
    </div>
  );
}

export default App;
